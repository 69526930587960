<template>
    <div class="drawer drawer-end">
        <input id="app-drawer" aria-hidden="true" name="app-drawer" v-model="drawerToggle" type="checkbox" class="drawer-toggle" />
        <div class="drawer-content flex flex-col">
            <div class="container max-w-full lg:mx-auto lg:max-w-6xl lg:p-8 2xl:px-0 min-h-[110%] lg:min-h-screen">
                <!-- <div className="flex-none lg:hidden absolute top-10 right-10 z-10">
                    <button aria-label="open sidebar" className="btn btn-square btn-ghost" @click="openDrawer">
                        <Icon name="ph:list-duotone" class="w-6 h-6 stroke-current"></Icon>
                    </button>
                </div> -->
                <AppHeader />
                <slot></slot>
                <slot name="footer-nav"></slot>
            </div>
        </div>
        <div class="drawer-side lg:hidden">
            <label for="app-drawer" class="drawer-overlay"></label>
            <ul class="menu p-4 w-80 bg-base-100 h-screen">
                <AppCommonNav @close-drawer="closeDrawer" :show-category="true" />
                <li class="menu-title">
                    <span>Settings</span>
                </li>
                <li class="hover-bordered py-1">
                    <NuxtLink to="/profile/settings/mine" active-class="hover-bordered" @click="closeDrawer">
                        <Icon name="settings-mine" class="h-6 w-6" />
                        My Settings
                    </NuxtLink>
                </li>
                <li class="py-1">
                    <NuxtLink to="/profile/settings/family" active-class="hover-bordered" @click="closeDrawer">
                        <Icon name="settings-family" class="h-6 w-6" />
                        My Family
                    </NuxtLink>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
const route = useRoute();
const drawerToggle = useState('drawerToggle', () => false);

const openDrawer = () => {
    drawerToggle.value = true;
};

const closeDrawer = () => {
    drawerToggle.value = false;
};
</script>
