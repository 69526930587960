<template>
    <li>
        <NuxtLink :to="links.home" class="auto-close-drawer" :active-class="activeClass" @click="$emit('closeDrawer')">
            <Icon name="ph:house-simple-duotone" class="h-6 w-6" />
            Home
        </NuxtLink>
    </li>
    <li class="menu-title" v-if="showCategory">
        <span>Apps</span>
    </li>
    <li>
        <NuxtLink :to="links['baby-tracker']" class="auto-close-drawer" :active-class="activeClass"
                  :class="checkForSubPages(links['baby-tracker']) ? 'active' : ''"
                  @click="$emit('closeDrawer')">
            <Icon name="baby-tracker" class="h-6 w-6" />
            Baby Tracker
        </NuxtLink>
    </li>
    <li>
        <NuxtLink :to="links.chores" class="auto-close-drawer" ref="choreLink" :active-class="activeClass"
                  :class="checkForSubPages(links.chores) ? 'active' : ''" @click="$emit('closeDrawer')">
            <Icon name="chores" class="h-6 w-6" />
            Chores
        </NuxtLink>
    </li>
    <li>
        <NuxtLink :to="links.rewards" class="auto-close-drawer" :class="checkForSubPages(links.rewards) ? 'active' : ''"
                  :active-class="activeClass" @click="$emit('closeDrawer')">
            <Icon name="rewards" class="h-6 w-6" />
            Rewards
        </NuxtLink>
    </li>
</template>
<style scoped lang="postcss">
.drawer-side li:not(:first-child) {
    @apply py-1
}
</style>
<script setup lang="ts">
defineEmits(['closeDrawer']);

withDefaults(defineProps<{
    activeClass?: string,
    showCategory?: boolean
}>(), {
    activeClass: 'active',
    showCategory: false
});

const links = {
    home: '/',
    ['baby-tracker']: '/baby-tracker',
    chores: '/chores',
    rewards: '/rewards'
};


const checkForSubPages = (link: string) => {
    const route = useRoute()
    return route.path.indexOf(link) >= 0;
};


</script>