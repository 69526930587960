<template>
    <div class="navbar w-full bg-base-300 lg:mb-10 lg:shadow-xl lg:rounded-xl lg:flex">
        <div class="flex-0">
            <NuxtLink class="logo" to="/" :aria-label="t('logo')">
                <app-logo />
            </NuxtLink>
        </div>
        <div class="flex-1">
        </div>
        <div class="flex-none align-middle">
            <ul class="menu menu-horizontal px-1 hidden lg:inline-flex lg:gap-4">
                <AppCommonNav />
            </ul>
            <ul class="menu menu-horizontal flex md:hidden">
                <label class="btn btn-ghost btn-circle avatar" @click="toggleDrawer">
                    <div class="w-10 rounded-full">
                        <img :src="user?.user_metadata?.avatar_url.replace('s96', 's40') || ''" alt="profile avatar"
                            referrerPolicy="no-referrer" />
                    </div>
                </label>
            </ul>
            <div class="dropdown dropdown-end hidden md:flex">
                <label tabindex="0" class="btn btn-ghost btn-circle avatar">
                    <div class="w-10 rounded-full">
                        <img :src="user?.user_metadata?.avatar_url.replace('s96', 's40') || ''" alt="profile avatar"
                            referrerPolicy="no-referrer" />
                    </div>
                </label>
                <ul tabindex="0"
                    class="menu menu-compact dropdown-content mt-3 p-2 drop-shadow-lg shadow  bg-base-100 rounded-box w-52 z-50">
                    <li>
                        <NuxtLink to="/profile/settings/mine" @click="menuBlur" class="flex">
                            <Icon name="settings-mine" class="h-6 w-6" />
                            My Settings
                        </NuxtLink>
                    </li>
                    <li>
                        <NuxtLink to="/profile/settings/family" @click="menuBlur" class="flex">
                            <Icon name="settings-family" class="h-6 w-6" />
                            Family Settings
                        </NuxtLink>
                    </li>
                    <li class="border-t-neutral-content border-t">
                        <a @click="signOutUser" href="profile" class="flex">
                            <Icon name="logout" class="h-6 w-6" />
                            Logout
                        </a>
                    </li>
                </ul>
            </div>
            <div class="dropdown dropdown-end inline-block lg:hidden" @click="toggleDrawer">
                <label aria-label="open sidebar" class="btn btn-square btn-ghost">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        class="inline-block w-6 h-6 stroke-current">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M4 6h16M4 12h16M4 18h16">
                        </path>
                    </svg>
                </label>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">

const supabase = useSupabaseClient();
const { t } = useI18n();

const dbStore = useDbStore();
await dbStore.awaiter();
const { user, profile } = storeToRefs(dbStore);

const settings = useSettingsStore();
const hasNotifications = ref<boolean>(false);

const menuBlur = ($event: MouseEvent) => {
    setTimeout(() => {
        ($event.target as HTMLElement).blur();
    }, 150);
}

const signOutUser = async ($event: MouseEvent) => {
    $event.preventDefault();
    await supabase.auth.signOut();
}

const toggleDrawer = () => {
    const drawerToggle = useState('drawerToggle');
    drawerToggle.value = !drawerToggle.value;
}

</script>
